<template>
  <div style="height: 100vh; background-color: white">
    <div v-show="showPdf == true">
      <!-- invoivce -->
      <div id="pdfportion">
        <div style="font-family: 'Acme', 'Lobster', 'Open Sans', sans-serif; font-size: 30px; font-style: italic; text-align: center; justify-content: center; padding-top: 10px; vertical-align: bottom">
          <div>
            <img :src="logo" style="max-width: 50px; left: 0" />
          </div>
          <div style="vertical-align: bottom; margin-top: -2vh">
            {{ company.company_name }}
          </div>
        </div>
        <div style="display: flex; justify-content: center">
          <div>
            <strong> </strong>
          </div>
        </div>
        <div style="display: flex; justify-content: center">
          <div>
            <!-- 123/5, BCS Cmputer City, IDB Bhaban -->
            {{ company.company_address }}
          </div>
        </div>
        <div style="display: flex; justify-content: center">Hotline: {{ company.contact_person_phone }}</div>
        <div class="columns">
          <div class="column is-12">
            <div style="float: left; margin-left: 10px; font-size: 18px">
              <p style="font-weight: 700">Date: {{ calcDate }}</p>
            </div>
            <div style="float: right; margin-right: 10px; font-size: 18px">
              <p style="font-weight: 700">Time: {{ calcTime }}</p>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column is-12" style="border-top: 1px solid #dbdbdb; border-bottom: 1px solid #dbdbdb">
            <div style="justify-content: left; float: left; margin-left: 10px; font-size: 18px; font-weight: 600">
              <p style="">Name: {{ custInfo.gender }} {{ custInfo.first_name }} {{ custInfo.last_name }}</p>
              <p>Phone: {{ custInfo.phone }}</p>
            </div>
            <div style="justify-content: left; float: right; margin-right: 10px; font-size: 18px; font-weight: 600">
              <p>Card No: {{ cardData.card_no }}</p>
            </div>
          </div>
        </div>

        <table class="table is-fullwidth">
          <thead>
            <tr>
              <th>Item</th>
              <th>Date</th>
              <th>Qnty</th>
              <th>Cost</th>
              <th>Discount/ S. Discount</th>
              <th>Final Trans</th>
            </tr>
          </thead>
          <tbody style="font-size: 15px">
            <tr v-for="bill in billData" :key="bill.id">
              <template v-if="bill.bill_item == 'Room Clearance'">
                <td>Payment ({{ bill.trx_type }})</td>
              </template>
              <template v-else>
                <td>{{ bill.bill_item }}</td>
              </template>
              <td>{{ bill.date }}</td>
              <template v-if="bill.bill_category == 'debit'">
                <td>{{ bill.item_quantity }}</td>
              </template>
              <template v-else>
                <td>N.A</td>
              </template>
              <template v-if="bill.bill_category == 'debit' && bill.type_bill == 'room'">
                <td>{{ (bill.cost / bill.item_quantity).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</td>
              </template>
              <template v-else-if="bill.bill_category == 'debit' && bill.type_bill == 'laundry'">
                <td>{{ bill.cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</td>
              </template>
              <template v-else-if="bill.bill_category == 'debit' && bill.type_bill == 'room_service'">
                <td>{{ bill.cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</td>
              </template>
              <template v-else>
                <td>{{ bill.cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</td>
              </template>
              <template v-if="bill.bill_category == 'debit'">
                <td>{{ bill.discount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} (0%)</td>
              </template>
              <template v-else>
                <td>{{ bill.special_discount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</td>
              </template>
              <template v-if="bill.bill_category == 'debit'">
                <td>{{ ((bill.cost / bill.item_quantity) * bill.item_quantity).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</td>
              </template>
              <template v-else>
                <td>(-){{ bill.cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</td>
              </template>
            </tr>
          </tbody>
        </table>
        <hr style="margin-top: -20px" />

        <!-- <div style="float:right; font-size:18px; margin-right:10px; font-weight:bold;">Total Bill: {{resInfo.total_amount}}</div> -->

        <div style="text-align: right; font-size: 18px; margin-right: 10px">Sub Total: {{ (parseInt(roomCost) + laundryCalculated + roomServiceCalculated).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} BDT</div>

        <div style="text-align: right; font-size: 18px; margin-right: 10px">VAT({{ companyInfo.tax }}%): {{ (roomCost * (companyInfo.tax / 100)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} BDT</div>

        <div style="text-align: right; font-size: 18px; margin-right: 10px">Service Charge({{ companyInfo.service_charge }}%): {{ (roomCost * (companyInfo.service_charge / 100)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} BDT</div>

        <template v-if="laundryVatAmount != 0 || laundryServiceCharge != 0">
          <div style="text-align: right; font-size: 18px; margin-right: 10px">Laundry VAT & SD({{ laundryVatAmount }}% | {{ laundryServiceCharge }}%): {{ laundryCalculatedDuty.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} BDT</div>
        </template>
        <template v-if="roomServiceVat != 0 || roomServiceServiceCharge != 0">
          <div style="text-align: right; font-size: 18px; margin-right: 10px">RoomService VAT & SD({{ roomServiceVat }}%|{{ roomServiceServiceCharge }}%): {{ roomServiceCalculatedDuty.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} BDT</div>
        </template>

        <div style="text-align: right; font-size: 18px; margin-right: 10px">Discount(s):(-) {{ resInfo.previous_discounts.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} BDT</div>

        <hr />
        <p style="text-align: right; margin-right: 10px; margin-top: -20px; font-size: 18px">Grand Total: {{ (laundryCalculated + roomServiceCalculated + laundryCalculatedDuty + roomServiceCalculatedDuty + roomCost + roomCost * (companyInfo.tax / 100) + roomCost * (companyInfo.service_charge / 100) - resInfo.previous_discounts).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} BDT</p>
        <br />
        <p style="text-align: right; margin-right: 10px; margin-top: -20px; font-size: 18px">Advance & Payment(s): (-) {{ (advanceCalculated + roomClearanceCalculated).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} BDT</p>

        <template v-if="resInfo.total_due != 0">
          <hr />
          <p style="text-align: right; margin-right: 10px; margin-top: -20px; font-size: 18px">
            Total Due(s):
            {{
              (laundryCalculated + roomServiceCalculated + laundryCalculatedDuty + roomServiceCalculatedDuty + roomCost + roomCost * (companyInfo.tax / 100) + roomCost * (companyInfo.service_charge / 100) - resInfo.previous_discounts - advanceCalculated - roomClearanceCalculated)
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }}
            BDT
          </p>
        </template>
        <template v-else>
          <hr />
          <p style="text-align: right; margin-right: 10px; margin-top: -20px; font-weight: bold; font-size: 18px; color: red">PAID</p>
        </template>
      </div>
    </div>

    <div class="">
      <div class="columns">
        <div class="column is-6">
          <div class="" style="height: 95vh; margin-left: 5px; margin-top: 5px">
            <div style="float: left; margin: 10px 0 0 5px">
              <div style="float: left">
                <p style="font-size: 25px">Room No: {{ resInfo.room }}</p>
              </div>

              <br />
              <br />

              <div class="box" style="overflow-y: auto; width: 41vw; height: 87vh; margin-top: -4px">
                <div v-if="showInfo == false">
                  <div style="font-size: 20px; font-weight: 500">
                    <div style="display: flex">
                      <p style="font-size: 20px; font-weight: bold">Name: &nbsp;</p>
                      <p style="font-weight: bold">{{ genderSelection }} {{ firstName }} {{ lastName }}</p>
                    </div>
                    <hr />
                    <div style="display: flex; margin-bottom: 5px">
                      <p style="font-size: 20px">Phone No: &nbsp;</p>
                      <p>{{ phone }}</p>
                    </div>

                    <div style="display: flex; margin-bottom: 5px">
                      <p style="font-size: 20px">Email: &nbsp;</p>
                      <p>{{ email }}</p>
                    </div>
                    <div style="display: flex; margin-bottom: 5px">
                      <p style="font-size: 20px">Address: &nbsp;</p>
                      <p>{{ address }}</p>
                    </div>
                    <div style="display: flex; margin-bottom: 5px">
                      <p style="font-size: 20px">Country: &nbsp;</p>
                      <p>{{ country }}</p>
                    </div>
                    <div style="display: flex; margin-bottom: 5px">
                      <p style="font-size: 20px">Nationality: &nbsp;</p>
                      <p>{{ nationality }}</p>
                    </div>
                    <div style="display: flex; margin-bottom: 5px">
                      <p style="font-size: 20px">Occupation: &nbsp;</p>
                      <p>{{ occupation }}</p>
                    </div>
                    <hr />
                    <div style="display: flex; margin-bottom: 5px">
                      <p style="font-size: 20px">NID/DL: &nbsp;</p>
                      <p>{{ nidDl }}</p>
                    </div>
                    <div style="display: flex; margin-bottom: 5px">
                      <p style="font-size: 20px">Passport No.: &nbsp;</p>
                      <p>{{ passport }}</p>
                    </div>
                    <br />
                    <div class="columns">
                      <div class="columns is-12">
                        <div style="display: flex; margin-left: 23px; margin-bottom: 10px">
                          <div style="display: flex">
                            <p style="font-size: 20px">Passport Issue.: &nbsp;</p>
                            <p>{{ doi }}</p>
                          </div>
                          <div style="display: flex; margin-left: 100px">
                            <p style="font-size: 20px">Passport Expiry.: &nbsp;</p>
                            <p>{{ dof }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style="display: flex">
                      <p style="font-size: 20px; margin-bottom: 5px">Visa Type.: &nbsp;</p>
                      <p>{{ visa }}</p>
                    </div>
                    <br />
                    <div class="columns">
                      <div class="columns is-12">
                        <div style="display: flex; margin-left: 23px">
                          <div style="display: flex">
                            <p style="font-size: 20px">Visa Issue.: &nbsp;</p>
                            <p>{{ vdoi }}</p>
                          </div>
                          <div style="display: flex; margin-left: 100px">
                            <p style="font-size: 20px">Visa Expiry.: &nbsp;</p>
                            <p>{{ vdof }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="columns">
                      <div class="column is-12">
                        <hr />
                        <div style="display: flex">
                          <p style="font-size: 20px">Secondary Rooms: &nbsp;</p>
                          <p>{{ resInfo.secondary_room }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-else>
                  <div class="field is-horizontal">
                    <div class="field-label is-normal" style="">
                      <label class="label" style="font-size: 18px; text-align: left">Phone:</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <p class="control">
                          <input class="input" type="text" placeholder="Phone No." v-model="phone" style="width: 210px; float: left" />
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="field is-horizontal">
                    <div class="field-label is-normal" style="">
                      <label class="label" style="font-size: 18px; text-align: left">Name:</label>
                    </div>
                    <div class="select is-fullwidth" style="width: 110px; margin-right: 10px">
                      <select v-model="genderSelection">
                        <option value="" hidden><b>Gender</b></option>
                        <option v-for="filter in genderTypes" :key="filter.id">{{ filter }}</option>
                      </select>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <p class="control">
                          <input class="input" type="text" placeholder="First Name" v-model="firstName" />
                        </p>
                      </div>
                      <div class="field">
                        <p class="control">
                          <input class="input" type="text" placeholder="Last Name" v-model="lastName" />
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="field is-horizontal">
                    <div class="field-label is-normal" style="">
                      <label class="label" style="font-size: 18px; text-align: left">Email:</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <p class="control">
                          <input class="input" type="text" placeholder="Email" v-model="email" style="width: 210px; float: left" />
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="field is-horizontal">
                    <div class="field-label is-normal" style="">
                      <label class="label" style="font-size: 18px; text-align: left">Address:</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <p class="control" style="width: 410px">
                          <textarea class="textarea" placeholder="Address" v-model="address" style="width: 200px"></textarea>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="field is-horizontal">
                    <div class="field-label is-normal" style="">
                      <label class="label" style="font-size: 18px; text-align: left">NID/DL:</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <p class="control" style="">
                          <input class="input" type="int" placeholder="Number" v-model="nidDl" style="width: 200px; float: left" />
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="field is-horizontal">
                    <div class="field-label is-normal" style="">
                      <label class="label" style="font-size: 18px; text-align: left">Country:</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <p class="control" style="">
                          <input class="input" type="text" placeholder="Country Name" v-model="country" style="width: 200px; float: left" />
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="field is-horizontal">
                    <div class="field-label is-normal" style="">
                      <label class="label" style="font-size: 18px; text-align: left">Nationality:</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <p class="control" style="">
                          <input class="input" type="text" placeholder="Nationality" v-model="nationality" style="width: 200px; float: left" />
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="field is-horizontal">
                    <div class="field-label is-normal" style="">
                      <label class="label" style="font-size: 18px; text-align: left">Occupation:</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <p class="control" style="">
                          <input class="input" type="int" placeholder="Info" v-model="occupation" style="width: 200px; float: left" />
                        </p>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div class="field is-horizontal">
                    <div class="field-label is-normal" style="">
                      <label class="label has-text-left" style="width: 110px; font-size: 18px">Passport No:</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <p class="control" style="">
                          <input class="input" type="int" placeholder="Number" v-model="passport" style="width: 200px; float: left" />
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="field is-horizontal">
                    <div class="field-label is-normal" style="">
                      <label class="label" style="font-size: 18px; text-align: left">Passport:</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <p class="control">
                          <input class="input" type="date" placeholder="Date Of Issue" v-model="doi" />
                        </p>
                      </div>
                      <div class="field">
                        <p style="font-size: 30px; color: #c2c2c2">/</p>
                      </div>
                      <div class="field">
                        <p class="control">
                          <input class="input" type="date" placeholder="Date Of Expiry" v-model="dof" />
                        </p>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div class="field is-horizontal" style="">
                    <div class="field-label is-normal" style="">
                      <label class="label" style="font-size: 18px; width: 85px; text-align: left">Visa Type:</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <p class="control" style="">
                          <input class="input" type="int" placeholder="Type" v-model="visa" style="width: 200px; float: left" />
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="field is-horizontal">
                    <div class="field-label is-normal" style="">
                      <label class="label" style="font-size: 18px; text-align: left; width: 50px">Visa:</label>
                    </div>
                    <div class="field-body" style="margin-left: -50px">
                      <div class="field">
                        <p class="control">
                          <input class="input" type="date" placeholder="Date Of Issue" v-model="vdoi" />
                        </p>
                      </div>
                      <div class="field">
                        <p style="font-size: 30px; color: #c2c2c2">/</p>
                      </div>
                      <div class="field">
                        <p class="control">
                          <input class="input" type="date" placeholder="Date Of Expiry" v-model="vdof" />
                        </p>
                      </div>
                    </div>
                  </div>
                  <input type="file" style="min-width: 70%" label="User Image" autogrow @change="OnFileUpload" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="column is-6" style="margin-top: 5px; padding-right: 20px">
          <div class="" style="height: 100%">
            <div v-if="showBellBoy == true">
              <div class="modal is-active" style="">
                <div class="modal-background" @click.prevent="showBellBoy = false"></div>
                <div class="modal-content" style="background: white; max-height: 100%; min-height: 100%; width: 50vw">
                  <div class="columns">
                    <div class="column is-12">
                      <div style="width: 50vw; background-color: #51627e">
                        <p style="color: white; font-size: 20px; font-weight: 700">BellBoy</p>
                      </div>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column is-12">
                      <div class="field is-horizontal">
                        <div class="field-label is-normal">
                          <label class="label" style="font-size: 18px">Primary Room:</label>
                        </div>
                        <div class="field-body">
                          <div class="field">
                            <p class="control" style="">
                              <input class="input" type="text" readonly v-model="primaryRoom" style="width: 410px; float: left" />
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="field is-horizontal">
                        <div class="field-label is-normal">
                          <label class="label" style="font-size: 18px">Secondary Room:</label>
                        </div>
                        <div class="field-body">
                          <div class="field">
                            <p class="control" style="">
                              <input class="input" type="text" readonly v-model="secondaryRoom" style="width: 410px; float: left" />
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="field is-horizontal">
                        <div class="field-label is-normal">
                          <label class="label" style="font-size: 18px">Token No:</label>
                        </div>
                        <div class="field-body">
                          <div class="field">
                            <p class="control" style="">
                              <input class="input" type="text" placeholder="Token Number" v-model="tokenNo" style="width: 410px; float: left" />
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="field is-horizontal" style="margin-top: 10px">
                        <div class="field-label is-normal" style="">
                          <label class="label" style="font-size: 18px">Special Instruction:</label>
                        </div>
                        <div class="field-body">
                          <div class="field">
                            <p class="control" style="width: 410px">
                              <textarea class="textarea" placeholder="Instruction" v-model="specialIns" style="width: 200px"></textarea>
                            </p>
                          </div>
                        </div>
                      </div>

                      <button class="button" @click.prevent="updateCheckIn()" style="background-color: #0c9ed9; color: white">Submit & Check In</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="billModal == true">
              <div class="modal is-active" style="align-items: start; flex-direction: row; justify-content: end">
                <div class="modal-background" @click.prevent="billModal = false"></div>
                <div class="modal-content" style="background: white; max-height: 100%; min-height: 100%; width: 50vw; margin-right: 0">
                  <div v-if="generatedBill == true">
                    <!-- generated bill -->

                    <div style="background-color: #51627e; color: white; font-size: 20px; font-weight: bold">Bills Table</div>
                    <div style="max-height: 115vh">
                      <table class="table is-fullwidth">
                        <thead>
                          <tr>
                            <th>Item</th>
                            <th>Date</th>
                            <th>Qnty</th>
                            <th>Cost</th>
                            <th>Discount</th>
                            <th>Final Transaction</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="bill in billData" :key="bill.id">
                            <th>{{ bill.bill_item }}</th>
                            <th>{{ bill.date }}</th>
                            <th>{{ bill.item_quantity.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</th>
                            <th>{{ (bill.cost / bill.item_quantity).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</th>
                            <th>{{ bill.discount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} (0%)</th>
                            <th>{{ ((bill.cost / bill.item_quantity) * bill.item_quantity).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</th>
                          </tr>
                        </tbody>
                      </table>
                      <hr style="margin-top: -20px" />
                      <!-- <div style="float:right; font-size:18px; margin-right:10px; font-weight:bold;">Total Bill: {{resInfo.total_amount}}</div> -->

                      <div style="float: right; font-size: 18px; margin-right: 10px; font-weight: bold; margin-top: -20px">Sub Total: {{ roomCost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} BDT</div>
                      <br />
                      <br />

                      <div style="float: right; font-size: 18px; margin-right: 10px; font-weight: bold; margin-top: -40px">VAT({{ this.companyInfo.tax }}%): {{ (roomCost * (this.companyInfo.tax / 100)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} BDT</div>
                      <br />
                      <br />
                      <div style="float: right; font-size: 18px; margin-right: 10px; font-weight: bold; margin-top: -60px">Service Charge({{ this.companyInfo.service_charge }}%): {{ (roomCost * (this.companyInfo.service_charge / 100)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} BDT</div>
                      <br />
                      <br />
                      <div style="float: right; font-size: 18px; margin-right: 10px; font-weight: bold; margin-top: -80px">Prev's Discount: {{ this.resInfo.previous_discounts.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} BDT</div>
                      <br />
                      <br />
                      <div style="float: right; font-size: 18px; margin-right: 10px; font-weight: bold; margin-top: -100px">Special's Discount: {{ this.amountDiscount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} BDT</div>
                      <br />
                      <br />

                      <hr style="margin-top: -120px" />
                      <p style="text-align: right; margin-right: 10px; margin-top: 0px; font-weight: bold; font-size: 18px">Grand Total: {{ (roomCost + roomCost * (this.companyInfo.tax / 100) + roomCost * (this.companyInfo.service_charge / 100) - this.resInfo.previous_discounts - this.amountDiscount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} BDT</p>

                      <br />

                      <div class="tabs is-centered is-medium" style="">
                        <ul>
                          <li v-bind:class="{ 'is-active': isA == 'cash' }"><a v-on:click="(isA = 'cash'), (trxType = 'cash')">Cash</a></li>
                          <li v-bind:class="{ 'is-active': isA == 'card' }"><a v-on:click="(isA = 'card'), (trxType = 'card')">Card</a></li>
                          <li v-bind:class="{ 'is-active': isA == 'bkash' }"><a v-on:click="(isA = 'bkash'), (trxType = 'bkash')">bkash</a></li>
                        </ul>
                      </div>

                      <div class="tab-contents">
                        <div class="content" v-bind:class="{ 'is-active': isA == 'cash' }" style="">
                          <div class="field is-horizontal">
                            <div class="field-label is-normal">
                              <label class="label" style="font-size: 18px; width: 145px">Special Discount:</label>
                            </div>
                            <div class="field-body">
                              <div class="field">
                                <p class="control" style="">
                                  <input class="input" type="text" placeholder="Discount Amount" v-model="amountDiscount" style="width: 200px" />
                                </p>
                              </div>
                            </div>
                          </div>
                          <div class="field is-horizontal">
                            <div class="field-label is-normal">
                              <label class="label" style="font-size: 18px; width: 180px">Remarks S. Discount:</label>
                            </div>
                            <div class="field-body">
                              <div class="field">
                                <p class="control" style="">
                                  <input class="input" type="text" placeholder="Reason" v-model="specialDiscountReason" style="width: 200px; margin-left: -30px" />
                                </p>
                              </div>
                            </div>
                          </div>
                          <div class="field is-horizontal">
                            <div class="field-label is-normal">
                              <label class="label" style="font-size: 18px; width: 145px">Amount:</label>
                            </div>
                            <div class="field-body">
                              <div class="field">
                                <p class="control">
                                  <input class="input" type="text" placeholder="Credit amount" v-model="amount_credited" style="width: 200px" />
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="tab-contents">
                        <div class="content" v-bind:class="{ 'is-active': isA == 'card' }" style="">
                          <div class="field is-horizontal">
                            <div class="field-label is-normal">
                              <label class="label" style="font-size: 18px; width: 145px">Special Discount:</label>
                            </div>
                            <div class="field-body">
                              <div class="field">
                                <p class="control" style="">
                                  <input class="input" type="text" placeholder="Discount Amount" v-model="amountDiscount" style="width: 200px" />
                                </p>
                              </div>
                            </div>
                          </div>
                          <div class="field is-horizontal">
                            <div class="field-label is-normal">
                              <label class="label" style="font-size: 18px; width: 180px">Remarks S. Discount:</label>
                            </div>
                            <div class="field-body">
                              <div class="field">
                                <p class="control" style="">
                                  <input class="input" type="text" placeholder="Reason" v-model="specialDiscountReason" style="width: 200px; margin-left: -30px" />
                                </p>
                              </div>
                            </div>
                          </div>
                          <div class="field is-horizontal">
                            <div class="field-label is-normal">
                              <label class="label" style="font-size: 18px; width: 145px">Amount:</label>
                            </div>
                            <div class="field-body">
                              <div class="field">
                                <p class="control">
                                  <input class="input" type="text" placeholder="Credit amount" v-model="amount_credited" style="width: 200px" />
                                </p>
                              </div>
                            </div>
                          </div>

                          <div class="field is-horizontal">
                            <div class="field-label is-normal">
                              <label class="label" style="font-size: 18px; width: 145px">Card no:</label>
                            </div>
                            <div class="field-body">
                              <div class="field">
                                <p class="control">
                                  <input class="input" type="text" placeholder="Discount amount" v-model="trans_id" style="width: 200px" />
                                </p>
                              </div>
                            </div>
                          </div>
                          <div class="field is-horizontal">
                            <div class="field-label is-normal">
                              <label class="label" style="font-size: 18px; width: 145px">Date of expiry:</label>
                            </div>
                            <div class="field-body">
                              <div class="field">
                                <p class="control">
                                  <input class="input" type="date" placeholder="Discount amount" v-model="doe_card" style="width: 200px" />
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="tab-contents">
                        <div class="content" v-bind:class="{ 'is-active': isA == 'bkash' }" style="">
                          <div class="field is-horizontal">
                            <div class="field-label is-normal">
                              <label class="label" style="font-size: 18px; width: 145px">Special Discount:</label>
                            </div>
                            <div class="field-body">
                              <div class="field">
                                <p class="control" style="">
                                  <input class="input" type="text" placeholder="Discount Amount" v-model="amountDiscount" style="width: 200px" />
                                </p>
                              </div>
                            </div>
                          </div>
                          <div class="field is-horizontal">
                            <div class="field-label is-normal">
                              <label class="label" style="font-size: 18px; width: 180px">Remarks S. Discount:</label>
                            </div>
                            <div class="field-body">
                              <div class="field">
                                <p class="control" style="">
                                  <input class="input" type="text" placeholder="Reason" v-model="specialDiscountReason" style="width: 200px; margin-left: -30px" />
                                </p>
                              </div>
                            </div>
                          </div>
                          <div class="field is-horizontal">
                            <div class="field-label is-normal">
                              <label class="label" style="font-size: 18px; width: 145px">Amount:</label>
                            </div>
                            <div class="field-body">
                              <div class="field">
                                <p class="control">
                                  <input class="input" type="text" placeholder="Credit amount" v-model="amount_credited" style="width: 200px" />
                                </p>
                              </div>
                            </div>
                          </div>

                          <div class="field is-horizontal">
                            <div class="field-label is-normal">
                              <label class="label" style="font-size: 18px; width: 145px">Transaction id:</label>
                            </div>
                            <div class="field-body">
                              <div class="field">
                                <p class="control">
                                  <input class="input" type="text" placeholder="Discount amount" v-model="trans_id" style="width: 200px" />
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div style="margin-top: 10px">
                      <div style="float: left; margin-left: 10px; background-color: ">
                        <button
                          class="button"
                          @click.prevent="
                            billModal = false;
                            generatedBill = false;
                          "
                          style=""
                        >
                          Back
                        </button>
                      </div>
                      <div style="float: right; margin-right: 10px; background-color: "><button class="button" @click.prevent="bookedPayment()" style="">Submit</button></div>
                    </div>
                  </div>
                  <div v-else>
                    <div v-if="showPayment == false">
                      <div style="background-color: #51627e; color: white; font-size: 20px; font-weight: bold">Bills Table</div>
                      <div style="height: 87vh; overflow-y: auto">
                        <table class="table is-fullwidth">
                          <thead>
                            <tr>
                              <th>Item</th>
                              <th>Date</th>
                              <th>Qnty</th>
                              <th>Cost</th>
                              <th>Discount/ S. Discount</th>
                              <th>Final Trans</th>
                            </tr>
                          </thead>
                          <tbody style="font-size: 15px">
                            <tr v-for="bill in billData" :key="bill.id">
                              <template v-if="bill.bill_item == 'Room Clearance'">
                                <td>Payment ({{ bill.trx_type }})</td>
                              </template>
                              <template v-else>
                                <td>{{ bill.bill_item }}</td>
                              </template>
                              <td>{{ bill.date }}</td>
                              <template v-if="bill.bill_category == 'debit'">
                                <td>{{ bill.item_quantity }}</td>
                              </template>
                              <template v-else>
                                <td>N.A</td>
                              </template>
                              <template v-if="bill.bill_category == 'debit' && bill.type_bill == 'room'">
                                <td>{{ (bill.cost / bill.item_quantity).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</td>
                              </template>
                              <template v-else-if="bill.bill_category == 'debit' && bill.type_bill == 'laundry'">
                                <td>{{ bill.cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</td>
                              </template>
                              <template v-else-if="bill.bill_category == 'debit' && bill.type_bill == 'room_service'">
                                <td>{{ bill.cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</td>
                              </template>
                              <template v-else>
                                <td>{{ bill.cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</td>
                              </template>
                              <template v-if="bill.bill_category == 'debit'">
                                <td>{{ bill.discount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} (0%)</td>
                              </template>
                              <template v-else>
                                <td>{{ bill.special_discount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</td>
                              </template>
                              <template v-if="bill.bill_category == 'debit'">
                                <td>{{ ((bill.cost / bill.item_quantity) * bill.item_quantity).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</td>
                              </template>
                              <template v-else>
                                <td>(-){{ bill.cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</td>
                              </template>
                            </tr>
                          </tbody>
                        </table>
                        <hr style="margin-top: -20px" />

                        <!-- <div style="float:right; font-size:18px; margin-right:10px; font-weight:bold;">Total Bill: {{resInfo.total_amount}}</div> -->

                        <div style="text-align: right; font-size: 18px; margin-right: 10px">Sub Total: {{ (parseInt(roomCost) + laundryCalculated + roomServiceCalculated).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} BDT</div>

                        <div style="text-align: right; font-size: 18px; margin-right: 10px">VAT({{ companyInfo.tax }}%): {{ (roomCost * (companyInfo.tax / 100)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} BDT</div>

                        <div style="text-align: right; font-size: 18px; margin-right: 10px">Service Charge({{ companyInfo.service_charge }}%): {{ (roomCost * (companyInfo.service_charge / 100)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} BDT</div>

                        <template v-if="laundryVatAmount != 0 || laundryServiceCharge != 0">
                          <div style="text-align: right; font-size: 18px; margin-right: 10px">Laundry VAT & SD({{ laundryVatAmount }}% | {{ laundryServiceCharge }}%): {{ laundryCalculatedDuty.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} BDT</div>
                        </template>
                        <template v-if="roomServiceVat != 0 || roomServiceServiceCharge != 0">
                          <div style="text-align: right; font-size: 18px; margin-right: 10px">RoomService VAT & SD({{ roomServiceVat }}%|{{ roomServiceServiceCharge }}%): {{ roomServiceCalculatedDuty.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} BDT</div>
                        </template>

                        <div style="text-align: right; font-size: 18px; margin-right: 10px">Discount(s):(-) {{ resInfo.previous_discounts.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} BDT</div>

                        <hr />
                        <p style="text-align: right; margin-right: 10px; margin-top: -20px; font-size: 18px">Grand Total: {{ (laundryCalculated + roomServiceCalculated + laundryCalculatedDuty + roomServiceCalculatedDuty + roomCost + roomCost * (companyInfo.tax / 100) + roomCost * (companyInfo.service_charge / 100) - resInfo.previous_discounts).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} BDT</p>
                        <br />
                        <p style="text-align: right; margin-right: 10px; margin-top: -20px; font-size: 18px">Advance & Payment(s): (-) {{ (advanceCalculated + roomClearanceCalculated).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} BDT</p>

                        <template v-if="resInfo.total_due != 0">
                          <hr />
                          <p style="text-align: right; margin-right: 10px; margin-top: -20px; font-size: 18px">Total Due(s): {{ (laundryCalculated + roomServiceCalculated + laundryCalculatedDuty + roomServiceCalculatedDuty + roomCost + roomCost * (companyInfo.tax / 100) + roomCost * (companyInfo.service_charge / 100) - resInfo.previous_discounts - advanceCalculated - roomClearanceCalculated).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} BDT</p>
                        </template>
                        <template v-else>
                          <hr />
                          <p style="text-align: right; margin-right: 10px; margin-top: -20px; font-weight: bold; font-size: 18px; color: red">PAID</p>
                        </template>
                        <br />
                        <br />
                        <template v-if="resInfo.total_due != 0">
                          <div style="margin-right: 20px; float: right"><button class="button" style="background-color: #26ade4; color: white" @click.prevent="showPayment = !showPayment">Payment</button></div>
                        </template>
                      </div>

                      <div style="float: left; margin-top: 0vh; margin-left: 10px; background-color: "><button class="button" @click.prevent="billModal = false" style="">Back</button></div>
                      <button
                        class="button"
                        style="float: right; background-color: #099dd9; color: white; margin-right: 10px"
                        @click.prevent="
                          (showPdf = true), dateCal();
                          printPDF();
                        "
                      >
                        Print
                      </button>
                    </div>
                    <div v-else>
                      <div class="tabs is-centered is-medium">
                        <ul>
                          <li v-bind:class="{ 'is-active': isA == 'cash' }"><a v-on:click="(isA = 'cash'), (trxType = 'cash')">Cash</a></li>
                          <li v-bind:class="{ 'is-active': isA == 'card' }"><a v-on:click="(isA = 'card'), (trxType = 'card')">Card</a></li>
                          <li v-bind:class="{ 'is-active': isA == 'bkash' }"><a v-on:click="(isA = 'bkash'), (trxType = 'bkash')">bkash</a></li>
                        </ul>
                      </div>
                      <div style="float: left; font-size: 18px; margin-left: 10px; font-weight: bold">Credited Amount: {{ resInfo.total_credit }}</div>
                      <div style="float: right; font-size: 18px; margin-right: 10px; font-weight: bold">Total Due: {{ resInfo.total_due }}</div>
                      <br />
                      <hr />
                      <div class="tab-contents">
                        <div class="content" v-bind:class="{ 'is-active': isA == 'cash' }" style="height: 70vh">
                          <div class="field is-horizontal">
                            <div class="field-label is-normal">
                              <label class="label" style="font-size: 18px; width: 145px">Special Discount:</label>
                            </div>
                            <div class="field-body">
                              <div class="field">
                                <p class="control" style="">
                                  <input class="input" type="text" placeholder="Discount Amount" v-model="amountDiscount" style="width: 200px" />
                                </p>
                              </div>
                            </div>
                          </div>
                          <div class="field is-horizontal">
                            <div class="field-label is-normal">
                              <label class="label" style="font-size: 18px; width: 180px">Remarks S. Discount:</label>
                            </div>
                            <div class="field-body">
                              <div class="field">
                                <p class="control" style="">
                                  <input class="input" type="text" placeholder="Reason" v-model="specialDiscountReason" style="width: 200px; margin-left: -30px" />
                                </p>
                              </div>
                            </div>
                          </div>
                          <div class="field is-horizontal">
                            <div class="field-label is-normal">
                              <label class="label" style="font-size: 18px; width: 145px">Amount:</label>
                            </div>
                            <div class="field-body">
                              <div class="field">
                                <p class="control">
                                  <input class="input" type="text" placeholder="Credit amount" v-model="amount_credited" style="width: 200px" />
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="tab-contents">
                        <div class="content" v-bind:class="{ 'is-active': isA == 'card' }" style="height: 70vh">
                          <div class="field is-horizontal">
                            <div class="field-label is-normal">
                              <label class="label" style="font-size: 18px; width: 145px">Special Discount:</label>
                            </div>
                            <div class="field-body">
                              <div class="field">
                                <p class="control" style="">
                                  <input class="input" type="text" placeholder="Discount Amount" v-model="amountDiscount" style="width: 200px" />
                                </p>
                              </div>
                            </div>
                          </div>
                          <div class="field is-horizontal">
                            <div class="field-label is-normal">
                              <label class="label" style="font-size: 18px; width: 180px">Remarks S. Discount:</label>
                            </div>
                            <div class="field-body">
                              <div class="field">
                                <p class="control" style="">
                                  <input class="input" type="text" placeholder="Reason" v-model="specialDiscountReason" style="width: 200px; margin-left: -30px" />
                                </p>
                              </div>
                            </div>
                          </div>
                          <div class="field is-horizontal">
                            <div class="field-label is-normal">
                              <label class="label" style="font-size: 18px; width: 145px">Amount:</label>
                            </div>
                            <div class="field-body">
                              <div class="field">
                                <p class="control">
                                  <input class="input" type="text" placeholder="Credit amount" v-model="amount_credited" style="width: 200px" />
                                </p>
                              </div>
                            </div>
                          </div>

                          <div class="field is-horizontal">
                            <div class="field-label is-normal">
                              <label class="label" style="font-size: 18px; width: 145px">Card no:</label>
                            </div>
                            <div class="field-body">
                              <div class="field">
                                <p class="control">
                                  <input class="input" type="text" placeholder="Discount amount" v-model="trans_id" style="width: 200px" />
                                </p>
                              </div>
                            </div>
                          </div>
                          <div class="field is-horizontal">
                            <div class="field-label is-normal">
                              <label class="label" style="font-size: 18px; width: 145px">Date of expiry:</label>
                            </div>
                            <div class="field-body">
                              <div class="field">
                                <p class="control">
                                  <input class="input" type="date" placeholder="Discount amount" v-model="doe_card" style="width: 200px" />
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="tab-contents">
                        <div class="content" v-bind:class="{ 'is-active': isA == 'bkash' }" style="height: 70vh">
                          <div class="field is-horizontal">
                            <div class="field-label is-normal">
                              <label class="label" style="font-size: 18px; width: 145px">Special Discount:</label>
                            </div>
                            <div class="field-body">
                              <div class="field">
                                <p class="control" style="">
                                  <input class="input" type="text" placeholder="Discount Amount" v-model="amountDiscount" style="width: 200px" />
                                </p>
                              </div>
                            </div>
                          </div>
                          <div class="field is-horizontal">
                            <div class="field-label is-normal">
                              <label class="label" style="font-size: 18px; width: 180px">Remarks S. Discount:</label>
                            </div>
                            <div class="field-body">
                              <div class="field">
                                <p class="control" style="">
                                  <input class="input" type="text" placeholder="Reason" v-model="specialDiscountReason" style="width: 200px; margin-left: -30px" />
                                </p>
                              </div>
                            </div>
                          </div>
                          <div class="field is-horizontal">
                            <div class="field-label is-normal">
                              <label class="label" style="font-size: 18px; width: 145px">Amount:</label>
                            </div>
                            <div class="field-body">
                              <div class="field">
                                <p class="control">
                                  <input class="input" type="text" placeholder="Credit amount" v-model="amount_credited" style="width: 200px" />
                                </p>
                              </div>
                            </div>
                          </div>

                          <div class="field is-horizontal">
                            <div class="field-label is-normal">
                              <label class="label" style="font-size: 18px; width: 145px">Transaction id:</label>
                            </div>
                            <div class="field-body">
                              <div class="field">
                                <p class="control">
                                  <input class="input" type="text" placeholder="Discount amount" v-model="trans_id" style="width: 200px" />
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div style="display: flex; margin-left: 20vw">
                        <div><button class="button" style="margin-right: 5px" @click.prevent="showPayment = false">Back</button></div>
                        <div><button class="button" style="background-color: #26ade4; color: white" @click.prevent="submitPayment()">submit</button></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="modRoom == true">
              <!-- modroom -->
              <div class="modal is-active" style="align-items: start; flex-direction: row; justify-content: end">
                <div class="modal-background" @click.prevent="modRoom = false"></div>
                <div class="modal-content" style="background: white; max-height: 100%; min-height: 100%; width: 50vw; margin-right: 0">
                  <div class="columns">
                    <div class="column is-10">
                      <div style="display: flex; margin-top: 10px">
                        <button class="button" style="margin: 0px 5px 0px 10px; background-color: #099dd9; color: white" @click="removeBool = true">Remove Room</button>
                        <button class="button" @click="removeBool = false" style="background-color: #099dd9; color: white">Transfer Room</button>
                      </div>
                    </div>
                    <div class="column is-2" style="margin-top: 10px">
                      <button class="button" @click="modRoom = false" style="background-color: #099dd9; color: white">back</button>
                    </div>
                  </div>

                  <div v-if="removeBool == true">
                    <p style="display: block; font-weight: 700; font-size: 22px; border-bottom: 1px solid black; margin-bottom: 10px; margin-top: 10px">Remove Room</p>
                    <p style="font-weight: 700; font-size: 20px">Primary Room</p>
                    <table class="table is-fullwidth">
                      <thead>
                        <tr>
                          <th>Room No</th>
                          <th>Type</th>
                          <th>Cost</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{{ resInfo.room }}</td>
                          <td>{{ primaryRoomInfo.type_name }}</td>
                          <td>{{ primaryRoomInfo.cost }}</td>
                        </tr>
                      </tbody>
                    </table>
                    <template v-if="secondaryRoomInfo.length != 0">
                      <p style="font-weight: 700; font-size: 20px">Secondary Room</p>
                      <table class="table is-fullwidth">
                        <thead>
                          <tr>
                            <th>Room No</th>
                            <th>Type</th>
                            <th>Cost</th>
                            <th>Remove Room</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(sroom, index) in secondaryRoomInfo" :key="sroom.id">
                            <td>{{ sroom.room_no }}</td>
                            <td>{{ sroom.type_name }}</td>
                            <td>{{ sroom.cost }}</td>
                            <td><button style="background-color: #099dd9; color: white" class="button" @click.prevent="removeRoom(sroom, index)">Remove</button></td>
                          </tr>
                        </tbody>
                      </table>
                    </template>
                  </div>
                  <div v-else>
                    <p style="display: block; font-weight: 700; font-size: 22px; border-bottom: 1px solid black; margin-bottom: 10px; margin-top: 10px">Transfer Room</p>
                    <div v-if="trasnferModal == false">
                      <p style="font-weight: 700; font-size: 20px">Primary Room</p>
                      <table class="table is-fullwidth">
                        <thead>
                          <tr>
                            <th>Room No</th>
                            <th>Type</th>
                            <th>Cost</th>
                            <th>Transfer Room</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{{ resInfo.room }}</td>
                            <td>{{ primaryRoomInfo.type_name }}</td>
                            <td>{{ primaryRoomInfo.cost }}</td>
                            <td>
                              <button
                                class="button"
                                @click.prevent="
                                  trasnferModal = true;
                                  SearchTransferDate();
                                  tansferPrimary = 'primary';
                                "
                                style="background-color: #099dd9; color: white"
                              >
                                Transfer
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <template v-if="secondaryRoomInfo.length != 0">
                        <p style="font-weight: 700; font-size: 20px">Secondary Room</p>
                        <table class="table is-fullwidth">
                          <thead>
                            <tr>
                              <th>Room No</th>
                              <th>Type</th>
                              <th>Cost</th>
                              <th>Transfer Room</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="sroom in secondaryRoomInfo" :key="sroom.id">
                              <td>{{ sroom.room_no }}</td>
                              <td>{{ sroom.type_name }}</td>
                              <td>{{ sroom.cost }}</td>
                              <td>
                                <button
                                  class="button"
                                  @click.prevent="
                                    trasnferModal = true;
                                    SearchTransferDate();
                                    tansferPrimary = 'secondary';
                                    secondaryTransfer = sroom.room_no;
                                  "
                                  style="background-color: #099dd9; color: white"
                                >
                                  Transfer
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </template>
                    </div>
                    <div v-else>
                      <div class="modal is-active" style="align-items: start; flex-direction: row; justify-content: end">
                        <div
                          class="modal-background"
                          @click.prevent="
                            trasnferModal = false;
                            TransferRoomSelect = '';
                          "
                        ></div>
                        <div class="modal-content" style="background: white; max-height: 100%; min-height: 100%; width: 50vw; margin-right: 0">
                          <div style="display: flex; justify-content: space-between">
                            <div style="align-self: center"><p style="font-size: 20px; font-weight: 500">Rooms available For Transfer</p></div>
                            <button
                              style="margin-right: 10px; margin-top: 5px; background-color: #099dd9; color: white"
                              @click="
                                trasnferModal = false;
                                TransferRoomSelect = '';
                              "
                              class="button"
                            >
                              Back
                            </button>
                          </div>

                          <hr />
                          <div v-if="TransferBool != false" style="">
                            <div class="columns">
                              <div class="column is-10">
                                <div style="display: flex; align-items: center">
                                  <p style="font-size: 20px; margin-left: 10px; color: red; font-weight: 550">Room Selected: {{ TransferRoomSelect.room_no }}</p>
                                  <template v-if="iconCancel == true">
                                    <div class="cancelIcon" data-tooltip="Clear Selected" style="">
                                      <i
                                        class="fa-solid fa-xmark"
                                        style="font-size: 30px; margin-left: 20px; margin-top: 0.5vh"
                                        @click.prevent="
                                          TransferRoomSelect = '';
                                          TransferBool = false;
                                        "
                                      ></i>
                                      <!-- <img src="../assets/modify.png" alt="human" style="width:25px;" @click.prevent="iconModal = 'modInfo', rejectModal = true, modalClose= true"> -->
                                    </div>
                                  </template>
                                </div>
                              </div>
                              <div class="column is-2">
                                <button style="margin-right: 10px; background-color: #099dd9; color: white" class="button" @click="submitTransferRoom()">Submit</button>
                              </div>
                            </div>
                          </div>
                          <table class="table is-fullwidth">
                            <thead>
                              <tr>
                                <th>Room No</th>
                                <th>Type</th>
                                <th>Cost</th>
                                <th>Transfer Room</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="sroom in roomVacantDataTemp" :key="sroom.id">
                                <td>{{ sroom.room_no }}</td>
                                <td>{{ sroom.type_name }}</td>
                                <td>{{ sroom.cost }}</td>
                                <td>
                                  <button
                                    class="button"
                                    style="background-color: #099dd9; color: white"
                                    :disabled="TransferBool == true"
                                    @click.prevent="
                                      TransferRoomSelect = sroom;
                                      TransferBool = true;
                                      iconCancel = true;
                                    "
                                  >
                                    Select
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div style="margin: 2px 0 5px 0; width: 100%">
              <div style="float: right">
                <template v-if="resInfo.status == 'reserved'">
                  <button class="button" @click.prevent="showBellBoy = true" style="color: white; background-color: #099dd9">Check In</button>
                  <button
                    class="button"
                    style="color: white; background-color: #099dd9; margin-left: 5px"
                    @click.prevent="
                      modRoom = true;
                      getModRoom();
                    "
                  >
                    Modify Room
                  </button>
                </template>
                <template v-if="resInfo.status == 'Checked In'">
                  <button class="button" style="margin-left: 5px; color: white; background-color: #099dd9"><router-link :to="{ name: 'checkout', params: { id: resInfo.id } }" style="color: white">Check Out</router-link></button>
                </template>

                <div style="float: right; margin-bottom: 5px; margin-left: 5px">
                  <button class="button" @click.prevent="showInfo = !showInfo" style="border: 1px solid #099dd9; background-color: white"><i class="fas fa-solid fa-pen" style="color: #099dd9; font-size: 19px"></i></button>
                </div>
              </div>
            </div>
            <br />
            <br />

            <div class="box" style="overflow-y: auto; width: 100%; height: 87vh; margin-top: 1px">
              <div v-if="showInfo == true">
                <div v-if="showExtendDateModal == true">
                  <div style="margin-top: 10px">
                    <button class="button" @click.prevent="showExtendDateModal = true" style="float: right; background-color: red; color: white">Cancel</button>
                  </div>

                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label" style="font-size: 20px; width: 150px; margin-left: 0px">Old Check Out:</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <p class="control">
                          <input class="input" type="date" placeholder="date" v-model="checkOutDate" readonly />
                        </p>
                      </div>
                      <div class="field">
                        <p class="control"></p>
                      </div>
                    </div>
                  </div>
                  <div style="margin-top: 10px">
                    <button class="button" @click.prevent="searchNewDate()" style="float: right; background-color: #099dd9; color: white">Search</button>
                  </div>
                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label" style="font-size: 20px; width: 160px; margin-left: 0px">New Check Out:</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <p class="control">
                          <input class="input" type="date" placeholder="date" v-model="checkOutDateNew" :min="checkFromDate()" />
                        </p>
                      </div>
                      <div class="field">
                        <p class="control"></p>
                      </div>
                    </div>
                  </div>
                  <div v-if="roomBool == 'available'">
                    <p>{{ roomText }}</p>
                    <div
                      class="textHover"
                      @click="
                        changeDate();
                        roomBool = 'changed';
                      "
                      style="color: #0c9ed9; cursor: pointer"
                    >
                      Change Date(s)
                    </div>
                  </div>
                  <div v-if="roomBool == 'notavailable'">
                    <p>{{ roomText }}</p>
                  </div>
                  <div v-if="roomBool == 'changed'">
                    <p>Reservations dates are successfully extended</p>
                  </div>
                </div>
                <div v-else>
                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label" style="font-size: 20px">Check In:</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <p class="control">
                          <input class="input" type="date" placeholder="date" v-model="checkInDate" readonly />
                        </p>
                      </div>
                      <div class="field">
                        <p class="control"></p>
                      </div>
                    </div>
                  </div>
                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label" style="font-size: 20px; width: 150px; margin-left: -45px">Check Out:</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <p class="control">
                          <input class="input" type="date" placeholder="date" v-model="checkOutDate" readonly />
                        </p>
                      </div>
                      <div class="field">
                        <p class="control"></p>
                      </div>
                    </div>
                  </div>
                  <div style="margin-bottom: 10px">
                    <button class="button" @click.prevent="showExtendDateModal = true">Extend Checkout Date</button>
                  </div>
                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label" style="font-size: 20px">Early_Check_In:</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <p class="control">
                          <input class="input" type="text" placeholder="Early Check In" v-model="earlyCheckIn" style="width: 200px; float: left" />
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label" style="font-size: 20px">Early_Check_Out:</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <p class="control">
                          <input class="input" type="text" placeholder="Early Check Out" v-model="earlyCheckOut" style="width: 200px; float: left" />
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label" style="font-size: 20px; width: 150px">Extra Breakfast:</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <p class="control">
                          <input class="input" type="text" placeholder="No. of extra breakfast" v-model="extraBreakfast" style="width: 200px; float: left" />
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label" style="font-size: 20px; width: 150px">No. of Guest:</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <p class="control">
                          <input class="input" type="text" placeholder="No. of Guest" v-model="guestNo" style="width: 200px; float: left" />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else style="overflow-y: auto; overflow-x: hidden; height: 76vh">
                <div class="columns">
                  <div class="column is-6">
                    <div style="float: left">
                      <p style="font-size: 20px; font-weight: 700">
                        Room Type: <span style="font-weight: 500">{{ roomType.room_type }}</span>
                      </p>
                    </div>
                  </div>
                  <div class="column is-6">
                    <div style="float: right">
                      <p style="font-size: 20px; font-weight: 500">
                        Res. Status: <span style="font-weight: 500">{{ resInfo.status }}</span>
                      </p>
                    </div>
                  </div>
                </div>

                <div class="columns">
                  <div class="columns is-12">
                    <div style="display: flex; margin-left: 10px; margin-top: 22px; margin-left: 23px">
                      <div style="display: flex; margin-right: 55px">
                        <p style="font-size: 18px">Check In Date: &nbsp;</p>
                        <p style="font-size: 18px">{{ checkInDate }}</p>
                      </div>
                      <div style="display: flex">
                        <p style="font-size: 18px">Check Out Date: &nbsp;</p>
                        <p style="font-size: 18px">{{ checkOutDate }}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <hr style="margin-top: 38px" />
                <div style="display: flex; margin-bottom: 5px">
                  <p style="font-size: 20px">No of guest: &nbsp;</p>
                  <p style="font-size: 20px">{{ guestNo }} Guests</p>
                </div>
                <div class="columns">
                  <div class="columns is-12">
                    <div style="display: flex; margin-left: 10px; margin-top: 22px; margin-left: 23px">
                      <div style="display: flex; margin-right: 220px">
                        <p style="font-size: 20px">Adults: &nbsp;</p>
                        <p style="font-size: 20px">{{ AdultsInfo }}</p>
                      </div>
                      <div style="display: flex">
                        <p style="font-size: 20px">Childs: &nbsp;</p>
                        <p style="font-size: 20px">{{ childInfo }}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div style="display: flex; margin-bottom: 5px">
                  <p style="font-size: 20px">Extra Breakfast: &nbsp;</p>
                  <p style="font-size: 20px; font-weight: 500">{{ extraBreakfast }}</p>
                </div>
                <div class="columns">
                  <div class="columns is-12">
                    <div style="display: flex; margin-left: 10px; margin-top: 22px; margin-left: 23px">
                      <div style="display: flex; margin-right: 55px">
                        <p style="font-size: 20px">Early CI Time: &nbsp;</p>
                        <p style="font-size: 20px">{{ earlyCheckIn }} N.A</p>
                      </div>
                      <div style="display: flex">
                        <p style="font-size: 20px">Late C0 Time: &nbsp;</p>
                        <p style="font-size: 20px">{{ earlyCheckOut }} N.A</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div style="display: flex; margin-bottom: 5px; width: 200px; height: 100px">
                  <p style="font-size: 20px">Special Instruction: &nbsp;</p>
                  <p style="font-size: 20px; font-weight: 500">{{ specialIns }}</p>
                </div>

                <template v-if="resInfo.status == 'booked' && resInfo.total_amount == null">
                  <button class="button" style="background-color: #099dd9; margin-left: 10px; color: white" @click.prevent="genBills()">Generate Bill</button>
                </template>
                <template v-if="resInfo.status != 'booked'">
                  <button class="button" style="background-color: #099dd9; margin-left: 10px; color: white" @click.prevent="getBills()">Show Bills</button>
                </template>
                <template v-if="resInfo.status == 'booked' && resInfo.total_credit == null && resInfo.total_amount != null">
                  <button class="button" @click.prevent="showBookedBill()">Show bill</button>
                </template>
              </div>

              <div style="margin-top: 5px; position: fixed; bottom: 0; margin-bottom: 45px; margin-left: 200px">
                <router-link class="button" :to="{ name: 'Reservation' }">Back</router-link>
                <template v-if="resInfo.status != 'Checked Out' && showInfo == false">
                  <button class="button" style="background-color: #099dd9; margin-left: 10px; color: white" @click.prevent="submitGuestAndResModInfo()">Submit</button>
                </template>
                <template v-if="resInfo.status == 'Checked Out' && showInfo == true">
                  <button class="button" disabled style="background-color: #099dd9; margin-left: 10px; color: white" @click.prevent="submitGuestInfo()">Submit</button>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toast } from 'bulma-toast';
import api from '../boot/axios';
import axios from '../boot/axios';
import html2pdf from 'html2pdf-jspdf2';
export default {
  data() {
    return {
      secondaryTransfer: '',
      tansferPrimary: '',
      iconCancel: false,
      TransferRoomSelect: '',
      TransferBool: false,
      roomText: '',
      roomBool: '',
      logo: null,
      showPdf: false,
      checkOutDateNew: null,
      showExtendDateModal: false,
      tokenNo: '',
      showBellBoy: false,
      statusTypes: ['Booked', 'Reserved', 'Checked In'],
      statusFilter: 'Checked In',
      modRoom: false,
      resInfo: '',
      email: '',
      firstName: '',
      lastName: '',
      address: '',
      phone: null,
      custId: null,
      checkInDate: null,
      checkInTime: null,
      checkOutDate: null,
      checkOutTime: null,
      custInfo: '',
      earlyCheckIn: null,
      earlyCheckOut: null,
      extraBreakfast: null,
      roomType: '',
      genderSelection: '',
      passport: '',
      nidDl: '',
      country: '',
      nationality: '',
      occupation: '',
      doi: '',
      dof: '',
      visa: '',
      vdoi: '',
      vdof: '',
      genderTypes: ['Mr.', 'Ms.', 'Mrs.'],
      showInfo: false,
      cardData: '',
      billData: [],
      billModal: false,
      showPayment: false,
      isA: 'cash',
      guestNo: '',
      childInfo: '',
      AdultsInfo: '',
      specialIns: '',
      tempSplit: [],
      roomNos: [],
      roomNosFlat: [],
      secondaryRooms: '',
      due_amount: '',
      creditCalculated: '',
      trxType: 'cash',
      roomCost: 0,
      companyInfo: '',
      userInfo: '',
      specialDiscountReason: '',
      roomsCollected: [],
      roomInfo: [],
      totalAmount: 0,
      generatedBill: false,
      amountDiscount: 0,
      amount_credited: '',
      advanceCalculated: 0,
      roomClearanceCalculated: 0,
      primaryRoomInfo: '',
      secondaryRoomInfo: [],
      primaryRoom: '',
      secondaryRoom: '',
      cusPicData: '',
      addBillCalculated: 0,
      laundryCalculated: 0,
      laundryCalculatedDuty: 0,
      roomServiceCalculatedDuty: 0,
      roomServiceCalculated: 0,
      laundryVatAmount: 0,
      laundryServiceCharge: 0,
      roomServiceVat: 0,
      roomServiceServiceCharge: 0,
      minFromDate: '',
      deleteData: [],
      resData: [],
      roomData: [],
      roomVacantData: [],
      roomVacantDataTemp: [],
      b1: false,
      b2: false,
      b3: false,
      b4: false,
      b5: false,
      tempResDataDelete: [],
      roomsConcated: '',
      calcDate: '',
      calcTime: '',
      company: '',
      cardString: '',
      totalCost: 0,
      roomDataNew: [],
      testroom: [],
      removeBool: true,
      trasnferModal: false,
    };
  },
  async created() {
    await this.getResInfo();
    await this.getCompanyInfo();
    await this.getCompany();
    await this.getLogo();
  },
  methods: {
    async submitTransferRoom() {
      let tempCard = '';
      console.log(this.resInfo);
      console.log(this.tansferPrimary);

      await api
        .get(`api/card/?rev_id=${this.resInfo[0].id}`)
        .then((res) => {
          tempCard = res.data[0];
        })
        .catch((err) => console.log(err));
      console.log(tempCard);
      await api
        .put(`api/card/${tempCard.card_no}/`, {
          card_no: tempCard.card_no,
          room_no: this.TransferRoomSelect.room_no,
        })
        .catch((err) => console.log(err));

      await api
        .post(`api/room_t/`, {
          card_no: tempCard.card_no,
          room_no_to: this.TransferRoomSelect.room_no,
          room_no_from: this.secondaryTransfer,
        })
        .catch((err) => console.log(err));
      if (this.tansferPrimary == 'primary') {
        await api
          .put(`api/reservation/${this.resInfo.id}/`, {
            status: this.resInfo.status,
            cust_id: this.resInfo.cust_id,
            room: this.TransferRoomSelect.room_no,
          })
          .then(
            toast({
              message: 'Primary Room Number has been changed',
              type: 'is-info',
              dismissible: true,
              pauseOnHover: true,
              duration: 2000,
              position: 'bottom-right',
            })
          )
          .catch((err) => console.log(err));

        console.log(this.TransferRoomSelect.room_no);
        this.TransferBool = false;
        this.trasnferModal = false;
        this.$router.go();
      }
      if (this.tansferPrimary == 'secondary') {
        console.log('second');
        //edithere
        let tempCard = '';
        let roomArray = [];
        let splitArray = [];

        splitArray = this.resInfo.secondary_room.split(',');
        for (let i = 0; i < splitArray.length; i++) {
          roomArray.push(splitArray[i]);
        }
        console.log(splitArray);
        console.log(roomArray);
        console.log(this.secondaryTransfer);
        let tempRoomFiltered = splitArray.filter((a) => a != this.secondaryTransfer);
        console.log(tempRoomFiltered);
        tempRoomFiltered.push(this.TransferRoomSelect.room_no);
        let roomsConcat = '';
        roomsConcat += tempRoomFiltered + ',';

        console.log(roomsConcat.slice(0, -1));

        await api
          .get(`api/card/?rev_id=${this.resInfo[0].id}`)
          .then((res) => {
            tempCard = res.data[0];
          })
          .catch((err) => console.log(err));
        console.log(tempCard);
        await api
          .put(`api/card/${tempCard.card_no}/`, {
            card_no: tempCard.card_no,
            secondary_room: roomsConcat.slice(0, -1),
          })
          .catch((err) => console.log(err));

        await api
          .post(`api/room_t/`, {
            card_no: tempCard.card_no,
            room_no_to: this.TransferRoomSelect.room_no,
            room_no_from: this.secondaryTransfer,
          })
          .catch((err) => console.log(err));

        await api
          .put(`api/reservation/${this.resInfo.id}/`, {
            status: this.resInfo.status,
            cust_id: this.resInfo.cust_id,
            secondary_room: roomsConcat.slice(0, -1),
          })
          .then(
            toast({
              message: 'SecondarynRoom Number has been changed',
              type: 'is-info',
              dismissible: true,
              pauseOnHover: true,
              duration: 2000,
              position: 'bottom-right',
            })
          )
          .catch((err) => console.log(err));
      }
      this.$router.go();
      this.transferPrimary = '';
    },
    async getLogo() {
      await axios
        .get('auth/hotel_logo/1/')
        .then((res) => {
          this.logo = res.data.pic;
        })
        .catch((err) => console.log(err));
    },
    async getCompany() {
      await axios
        .get('/auth/company/1/')
        .then((res) => {
          this.company = res.data;
          console.log(this.company);
        })
        .catch((err) => console.log(err));
    },
    dateCal() {
      this.calcDate = new Date().toLocaleString('en-US', { timeZone: 'Asia/Dhaka' }).substr(0, 9);
      this.calcTime = new Date().toLocaleString('en-US', { timeZone: 'Asia/Dhaka' }).substr(10, 11);
    },
    printPDF() {
      this.showPdf = true;
      var element = document.getElementById('pdfportion');

      var opt = {
        margin: 0.2,
        filename: `${this.cardData.card_no}.pdf`,
        image: { type: 'png' },
        html2canvas: { scale: 2 },
        pagebreak: { mode: 'avoid-all' },
        jsPDF: {
          orientation: 'p',
          unit: 'in',
          format: 'a4',
          putOnlyUsedFonts: true,
          floatPrecision: 16,
        },
      };

      html2pdf().set(opt).from(element).save();
      this.showPdf = false;
    },
    async getCompanyInfo() {
      // await axios.get('/auth/currentuser/'
      //     ).then(res =>{
      //         console.log(res.data.role)
      //         this.userInfo = res.data.company
      //     }).catch(err => console.log(err))
      console.log(this.userInfo);

      await axios
        .get(`/auth/company/1/`)
        .then((res) => {
          this.companyInfo = res.data;
        })
        .catch((err) => console.log(err));
      console.log(this.companyInfo);
    },
    checkFromDate() {
      this.minFromDate = this.checkOutDate;
      return this.minFromDate;
    },
    async changeDate() {
      console.log(this.resInfo);
      let roomArray = [];
      let splitArray = [];

      let dayCount = 0;
      roomArray.push(this.resInfo.room);
      splitArray = this.resInfo.secondary_room.split(',');
      for (let i = 0; i < splitArray.length; i++) {
        roomArray.push(splitArray[i]);
      }
      console.log(splitArray);
      console.log(roomArray);

      dayCount = (new Date(this.checkOutDateNew) - new Date(this.checkOutDate)) / (1000 * 3600 * 24);
      this.cardString = this.resInfo.room + this.resInfo.id.toString().padStart(5, '0');

      for (let i = 0; i < roomArray.length; i++) {
        await api
          .get(`api/rmrt/?room_no=${roomArray[i]}`)
          .then((res) => {
            this.roomDataNew.push(res.data);
          })
          .catch((err) => console.log(err));
      }
      // this.roomDataNew = this.roomDataNew.flat()
      console.log(this.roomDataNew, 'yo yo yo');
      console.log(this.roomDataNew.flat(), 'yo yo');
      this.testroom = this.roomDataNew.map((el) => el[0]);
      console.log(this.testroom);

      for (let i = 0; i < this.testroom.length; i++) {
        console.log('yo', this.testroom[0].cost);
        this.totalCost += parseInt(parseInt(this.testroom[i].cost) * parseInt(dayCount) + parseInt(this.testroom[i].cost) * parseInt(dayCount) * (this.companyInfo.tax / 100) + parseInt(this.testroom[i].cost) * parseInt(dayCount) * (this.companyInfo.service_charge / 100));
      }
      console.log(this.companyInfo);
      console.log(dayCount);
      console.log(this.cardString);
      console.log(this.totalCost);
      for (let i = 0; i < this.testroom.length; i++) {
        await api
          .post(`api/bills/`, {
            type_bill: 'room_rent',
            cost: this.testroom[i].cost * dayCount,
            vat_amount: this.testroom[i].cost * dayCount * (this.companyInfo.tax / 100),
            sc_amount: this.testroom[i].cost * dayCount * (this.companyInfo.service_charge / 100),
            discount: this.testroom[i].discount,
            is_paid: false,
            bill_item: `room ${this.testroom[i].room_no} extend bill`,
            card_no: this.cardString,
            item_quantity: dayCount,
            bill_category: 'debit',
            special_discount: 0,
          })
          .catch((err) => console.log(err));
      }
      await api
        .put(`api/reservation/${this.resInfo.id}/`, {
          status: this.resInfo.status,
          total_amount: parseInt(this.resInfo.total_amount) + parseInt(this.totalCost),
          total_due: parseInt(this.resInfo.total_due) + parseInt(this.totalCost),
          cust_id: this.resInfo.cust_id,
          check_out_date: this.checkOutDateNew,
        })
        .catch((err) => console.log(err));
    },
    async searchNewDate() {
      this.roomBool = '';
      this.roomText = '';
      this.checkOutDate;

      this.checkOutDateNew;
      this.resData = [];
      this.roomVacantData = [];
      this.roomVacantDataTemp = [];
      this.roomData = [];
      let co = this.checkOutDate;
      let cd = this.checkOutDateNew;

      // await api.get('rmrt/'
      //     ).then(res =>{
      //         this.roomData = res.data
      //         //console.log("room",this.roomData)
      //     }).catch(err => console.log(err))
      this.roomData = this.roomNosFlat;
      await api
        .get('api/reservation/')
        .then((res) => {
          this.resData = res.data;
          //console.log("res",this.resData)
        })
        .catch((err) => console.log(err));

      let varr = [];

      for (let i = 0; i < this.resData.length; i++) {
        const a = {
          room: parseInt(this.resData[i].room),
          check_in_date: this.resData[i].check_in_date,
          check_out_date: this.resData[i].check_out_date,
          status: this.resData[i].status,
        };
        varr.push(a);
        if (this.resData[i].secondary_room != '' && this.resData[i].secondary_room != null) {
          let b = this.resData[i].secondary_room.split(',');
          for (let j = 0; j < b.length; j++) {
            const c = {
              room: parseInt(b[j]),
              check_in_date: this.resData[i].check_in_date,
              check_out_date: this.resData[i].check_out_date,
              status: this.resData[i].status,
            };
            varr.push(c);
          }
        }
      }
      this.resData = varr;

      if (cd != '' && co != '') {
        for (let i = 0; i < this.roomData.length; i++) {
          this.b1 = false;
          this.b2 = false;
          this.b3 = false;
          this.b4 = false;
          this.b5 = false;
          for (let a = 0; a < this.resData.length; a++) {
            if (this.roomData[i] == this.resData[a].room && this.resData[a].status != 'booked') {
              if (this.resData[a].status == 'OnHold') {
                this.b5 = true;
                break;
              }
              if (cd > this.resData[a].check_in_date && cd < this.resData[a].check_out_date) {
                // console.log(true)
                this.b1 = true;
                break;
              }
              if (co > this.resData[a].check_in_date && co < this.resData[a].check_out_date) {
                //console.log(true)
                this.b2 = true;
                break;
              }
              if (this.resData[a].check_in_date > co && this.resData[a].check_in_date < cd) {
                // console.log(true)
                this.b3 = true;
                break;
              }
              if (this.resData[a].check_out_date > co && this.resData[a].check_out_date < cd) {
                //console.log(true)
                this.b4 = true;
                break;
              }
              if (this.resData[a].check_in_date == co && this.resData[a].check_out_date == cd) {
                this.b1 = true;
                break;
              }
            }
          }
          if (this.b1 == false && this.b2 == false && this.b3 == false && this.b4 == false && this.b5 == false) {
            this.roomVacantData.push(this.roomData[i]);
          }
        }
        this.roomVacantDataTemp = this.roomVacantData;
        console.log('temp1', this.roomData);
        console.log('temp2', this.roomVacantDataTemp);
        if (this.roomData.length == this.roomVacantDataTemp.length) {
          console.log(true);
          //edithere
          this.roomBool = 'available';
          this.roomText = 'Room is available for the paricular date(s)';
        } else {
          console.log(false);
          this.roomBool = 'notavailable';
          this.roomText = 'Room is not available for the paricular date(s)';
        }
      }
    },
    async SearchTransferDate() {
      console.log('i am called');

      this.roomBool = '';
      this.roomText = '';
      this.checkOutDate;

      this.checkOutDateNew;
      this.resData = [];
      this.roomVacantData = [];
      this.roomVacantDataTemp = [];
      this.roomData = [];
      let co = this.checkInDate;
      let cd = this.checkOutDate;

      await api
        .get('api/rmrt/')
        .then((res) => {
          this.roomData = res.data;
          //console.log("room",this.roomData)
        })
        .catch((err) => console.log(err));
      await api
        .get('api/reservation/')
        .then((res) => {
          this.resData = res.data;
          //console.log("res",this.resData)
        })
        .catch((err) => console.log(err));

      let varr = [];
      for (let i = 0; i < this.resData.length; i++) {
        const a = {
          room: parseInt(this.resData[i].room),
          check_in_date: this.resData[i].check_in_date,
          check_out_date: this.resData[i].check_out_date,
          status: this.resData[i].status,
        };
        varr.push(a);
        if (this.resData[i].secondary_room != '' && this.resData[i].secondary_room != null) {
          let b = this.resData[i].secondary_room.split(',');
          for (let j = 0; j < b.length; j++) {
            const c = {
              room: parseInt(b[j]),
              check_in_date: this.resData[i].check_in_date,
              check_out_date: this.resData[i].check_out_date,
              status: this.resData[i].status,
            };
            varr.push(c);
          }
        }
      }
      this.resData = varr;

      if (cd != '' && co != '') {
        for (let i = 0; i < this.roomData.length; i++) {
          this.b1 = false;
          this.b2 = false;
          this.b3 = false;
          this.b4 = false;
          this.b5 = false;

          let b11 = false;
          let b22 = false;
          let b33 = false;

          for (let a = 0; a < this.resData.length; a++) {
            if (this.resData[a].status == 'booked') {
              if (co >= this.resData[a].check_in_date && co < this.resData[a].check_out_date) {
                b22 = true;
              }
              if (cd > this.resData[a].check_in_date && cd <= this.resData[a].check_out_date) {
                b11 = true;
              }
              if (co < this.resData[a].check_in_date && cd > this.resData[a].check_out_date) {
                b33 = true;
              }
            }
            if (b11 == true || b22 == true || b33 == true) {
              if (!this.roomBooked.includes(this.resData[a])) {
                this.roomBooked.push(this.resData[a]);
              }
            }
            b11 = false;
            b22 = false;
            b33 = false;

            if (this.roomData[i].room_no == this.resData[a].room && this.resData[a].status != 'booked') {
              if (this.resData[a].status == 'OnHold') {
                this.b5 = true;
                break;
              }
              if (this.resData[a].status == 'Checked In') {
                this.b5 = true;
                break;
              }
              if (cd > this.resData[a].check_in_date && cd < this.resData[a].check_out_date) {
                // console.log(true)
                this.b1 = true;
                break;
              }
              if (co > this.resData[a].check_in_date && co < this.resData[a].check_out_date) {
                //console.log(true)
                this.b2 = true;
                break;
              }
              if (this.resData[a].check_in_date > co && this.resData[a].check_in_date < cd) {
                // console.log(true)
                this.b3 = true;
                break;
              }
              if (this.resData[a].check_out_date > co && this.resData[a].check_out_date < cd) {
                //console.log(true)
                this.b4 = true;
                break;
              }
              if (this.resData[a].check_in_date == co && this.resData[a].check_out_date == cd) {
                this.b1 = true;
                break;
              }
            }
          }
          if (this.b1 == false && this.b2 == false && this.b3 == false && this.b4 == false && this.b5 == false) {
            this.roomVacantData.push(this.roomData[i]);
          }
        }
        this.roomVacantDataTemp = this.roomVacantData;
        console.log('temp', this.roomVacantDataTemp);

        console.log('value should be here');

        console.log(this.roomVacantData);
      }
    },
    async removeRoom(room, index) {
      let tempSecondaryRoomVal = null;
      let tempRoom = this.secondaryRoomInfo;
      let tempRoomFiltered = tempRoom.filter((a) => a.room_no == room.room_no);
      console.log('temp', tempRoom);
      console.log('temp', tempRoomFiltered);
      console.log('primary', this.primaryRoomInfo);
      console.log('resInfo', this.resInfo);

      if (tempRoomFiltered.length == 0) {
        tempSecondaryRoomVal = null;
      } else {
        for (let i = 0; i < tempRoomFiltered.length; i++) {
          console.log('hello');
          this.roomsConcated += tempRoomFiltered[i].room_no + ',';
        }
        tempSecondaryRoomVal = this.roomsConcated.slice(0, -1);
      }
      console.log('this', tempSecondaryRoomVal);
      console.log('temp', tempRoomFiltered);
      console.log(index);

      console.log('room no', room.room_no);

      console.log(this.companyInfo);
      console.log(room.cost * this.resInfo.No_days + room.cost * this.resInfo.No_days * (this.companyInfo.tax / 100) + room.cost * this.resInfo.No_days * (this.companyInfo.service_charge / 100));
      console.log(this.resInfo.No_days);

      console.log(this.resInfo);
      await api
        .put(`api/reservation/${this.resInfo.id}/`, {
          cust_id: this.resInfo.cust_id,
          secondary_room: tempSecondaryRoomVal,
          total_due: parseInt(this.resInfo.total_due) - room.cost * this.resInfo.No_days + room.cost * this.resInfo.No_days * (this.companyInfo.tax / 100) + room.cost * this.resInfo.No_days * (this.companyInfo.service_charge / 100),
          total_amount: parseInt(this.resInfo.total_amount) - room.cost * this.resInfo.No_days + room.cost * this.resInfo.No_days * (this.companyInfo.tax / 100) + room.cost * this.resInfo.No_days * (this.companyInfo.service_charge / 100),
          room: this.resInfo.room,
          status: this.resInfo.status,
        })
        .then(
          toast({
            message: `Room ${room.room_no} has been removed`,
            type: 'is-info',
            dismissible: true,
            pauseOnHover: true,
            duration: 2000,
            position: 'bottom-right',
          })
        )
        .catch((err) => console.log(err));
      console.log(this.resInfo);

      await api
        .get(`api/card/?rev_id=${this.$route.params.id}`)
        .then((res) => {
          this.cardData = res.data[0];
          console.log(this.cardData);
        })
        .catch((err) => console.log(err));
      console.log('card', this.cardData.card_no);
      await api
        .get(`api/bills/?card_no=${this.cardData.card_no}&bill_item=room+${room.room_no}`)
        .then((res) => {
          this.deleteData = res.data;
        })
        .catch((err) => console.log(err));
      console.log(this.deleteData[0]);

      await api.delete(`api/bills/${this.deleteData[0].id}/`).catch((err) => console.log(err));

      //edit trans table

      this.$router.go();
    },
    async getResInfo() {
      await api
        .get(`api/reservation/${this.$route.params.id}/`)
        .then((res) => {
          this.resInfo = res.data;
          console.log(this.resInfo);
        })
        .catch((err) => {
          console.log(err);
        });
      this.checkInDate = this.resInfo.check_in_date;
      this.earlyCheckIn = this.resInfo.check_in_time;
      this.checkOutDate = this.resInfo.check_out_date;
      this.earlyCheckOut = this.resInfo.check_out_time;
      this.guestNo = this.resInfo.no_guest;
      this.AdultsInfo = this.resInfo.adults;
      this.childInfo = this.resInfo.childs;
      this.extraBreakfast = this.resInfo.extra_breakfast;
      this.specialIns = this.resInfo.special_ins;
      this.primaryRoom = this.resInfo.room;
      this.secondaryRoom = this.resInfo.secondary_room;
      console.log('info', this.resInfo);
      //slice(0,-1)

      this.roomNos.push(this.resInfo.room);

      if (this.resInfo.secondary_room != null && this.resInfo.secondary_room != '') {
        this.tempSplit = this.resInfo.secondary_room.split(',');

        console.log('yo man', this.tempSplit);
      }
      this.roomNos.push(this.tempSplit);
      this.roomNosFlat = this.roomNos.flat();

      // get secondary reservation room
      await api
        .get(`api/reservation/?primary_room_no=${this.resInfo.room}`)
        .then((res) => {
          this.secondaryRooms = res.data;
          console.log('rooms sendondary', this.secondaryRooms);
        })
        .catch((err) => {
          console.log(err);
        });

      //customer info
      await api
        .get(`api/customer/${this.resInfo.cust_id}/`)
        .then((res) => {
          this.custInfo = res.data;
          console.log(this.custInfo);
        })
        .catch((err) => {
          console.log(err);
        });
      this.firstName = this.custInfo.first_name;
      this.lastName = this.custInfo.last_name;
      this.phone = this.custInfo.phone;
      this.email = this.custInfo.email;
      this.address = this.custInfo.address;
      this.genderSelection = this.custInfo.gender;
      this.passport = this.custInfo.passport;
      this.nidDl = this.custInfo.nid;
      this.dof = this.custInfo.passport_exp;
      this.visa = this.custInfo.visa_type;
      this.vdoi = this.custInfo.visa_issue;
      this.vdof = this.custInfo.visa_expiry;
      this.nationality = this.custInfo.nationality;
      this.country = this.custInfo.country;
      this.occupation = this.custInfo.occupation;

      await api.get(`api/room/${this.resInfo.room}/`).then((res) => {
        this.roomType = res.data;
        console.log('room', this.roomType);
      });
    },
    async updateCheckIn() {
      let slicedRoom = null;
      if (this.resInfo.secondary_room != '' && this.resInfo.secondary_room != null) {
        slicedRoom = this.resInfo.secondary_room;
      } else {
        slicedRoom = this.resInfo.secondary_room;
      }
      console.log(slicedRoom);
      await api
        .post(`api/bellboy/`, {
          check: true,
          token: this.tokenNo,
          room_no: this.resInfo.room,
          status: false,
          room_secondary: slicedRoom,
          special_ins: this.specialIns,
        })
        .catch((err) => console.log(err));

      if (this.resInfo.status != 'booked') {
        await api
          .put(`api/reservation/${this.$route.params.id}/`, {
            status: this.statusFilter,
            cust_id: this.resInfo.cust_id,
          })
          .then(
            toast({
              message: 'Rooms Checked In',
              type: 'is-info',
              dismissible: true,
              pauseOnHover: true,
              duration: 2000,
              position: 'bottom-right',
            })
          )
          .catch((err) => {
            console.log(err);
          });

        for (let i = 0; i < this.secondaryRooms.length; i++) {
          await api
            .put(`api/reservation/${this.secondaryRooms[i].id}/`, {
              status: this.statusFilter,
              cust_id: this.resInfo.cust_id,
            })
            .catch((err) => {
              console.log(err);
            });
        }

        console.log('why i am not defined', this.roomNosFlat);

        for (let index = 0; index < this.roomNosFlat.length; index++) {
          await api
            .put(`api/room/${this.roomNosFlat[index]}/`, {
              status_fd: 'occupied',
              room_no: this.roomNosFlat[index],
            })
            .catch((err) => {
              console.log(err);
            });
        }

        await api
          .get(`api/card/?rev_id=${this.$route.params.id}`)
          .then((res) => {
            this.cardData = res.data[0];
            console.log(this.cardData);
          })
          .catch((err) => console.log(err));

        await api
          .put(`api/card/${this.cardData.card_no}/`, {
            card_no: this.cardData.card_no,
            checked_in: true,
          })
          .catch((err) => console.log(err));

        location.reload();
      }
    },
    async getBills() {
      if (this.resInfo.previous_discounts == null) {
        this.resInfo.previous_discounts = 0;
      }

      //edit
      this.roomCost = 0;
      this.laundryCalculated = 0;
      this.roomServiceCalculated = 0;
      this.laundryCalculatedDuty = 0;
      this.roomServiceCalculatedDuty = 0;
      this.dayCount = 0;
      this.advanceCalculated = 0;
      this.roomClearanceCalculated = 0;
      await api.get(`api/card/?rev_id=${this.$route.params.id}`).then((res) => {
        this.cardData = res.data[0];
        console.log(this.cardData);
      });

      await api.get(`api/bills/?card_no=${this.cardData.card_no}`).then((res) => {
        this.billData = res.data;
        console.log(this.billData);
      });
      console.log('this bill', this.billData);

      for (let i = 0; i < this.billData.length; i++) {
        console.log(this.billData[i]);
        if (this.billData[i].bill_category == 'debit' && this.billData[i].type_bill == 'room_rent') {
          this.roomCost += parseInt((this.billData[i].cost / this.billData[i].item_quantity) * this.billData[i].item_quantity);
        }
        // if(this.billData[i].bill_category == 'credit'){
        //     this.roomCost -= parseInt(this.billData[i].cost)
        // }
      }
      console.table(this.billData);
      for (let i = 0; i < this.billData.length; i++) {
        if (this.billData[i].bill_category == 'credit' && this.billData[i].bill_item != 'Room Clearance') {
          this.advanceCalculated += parseInt(this.billData[i].cost);
        }
        if (this.billData[i].bill_category == 'credit' && this.billData[i].bill_item == 'Room Clearance') {
          this.roomClearanceCalculated += parseInt(this.billData[i].cost);
        }
      }

      for (let i = 0; i < this.billData.length; i++) {
        if (this.billData[i].bill_category == 'debit' && this.billData[i].type_bill == 'laundry') {
          console.log('yo yo', this.billData[i]);
          this.laundryCalculated += parseInt(this.billData[i].cost);
          this.laundryCalculatedDuty = (parseInt(this.billData[i].cost) * parseInt(this.billData[i].vat_amount)) / 100 + (parseInt(this.billData[i].cost) * parseInt(this.billData[i].sc_amount)) / 100;
          this.laundryVatAmount = parseInt(this.billData[i].vat_amount);
          this.laundryServiceCharge = parseInt(this.billData[i].sc_amount);
        }
        if (this.billData[i].bill_category == 'debit' && this.billData[i].type_bill == 'room_service') {
          this.roomServiceCalculated += parseInt(this.billData[i].cost);
          this.roomServiceCalculatedDuty = (parseInt(this.billData[i].cost) * parseInt(this.billData[i].vat_amount)) / 100 + (parseInt(this.billData[i].cost) * parseInt(this.billData[i].sc_amount)) / 100;
          this.roomServiceVat = parseInt(this.billData[i].vat_amount);
          this.roomServiceServiceCharge = parseInt(this.billData[i].sc_amount);
        }
      }

      console.log('lo lo', this.roomCost);
      console.log('prev discounts', this.resInfo);
      console.log('first');
      this.billModal = true;
    },

    async submitPayment() {
      console.log(this.resInfo.total_due);
      console.log(this.amount_credited);
      this.due_amount = parseInt(this.resInfo.total_due) - this.amountDiscount - this.amount_credited;
      console.log(this.due_amount);
      this.creditCalculated = parseInt(this.resInfo.total_credit) + parseInt(this.amount_credited);
      console.log(this.creditCalculated);

      await api
        .put(`api/reservation/${this.resInfo.id}/`, {
          cust_id: this.resInfo.cust_id,
          status: this.resInfo.status,
          total_due: this.due_amount,
          total_credit: this.creditCalculated,
          previous_discounts: parseInt(this.resInfo.previous_discounts) + parseInt(this.amountDiscount),
        })
        .then(
          toast({
            message: 'Bill payment updated',
            type: 'is-info',
            dismissible: true,
            pauseOnHover: true,
            duration: 2000,
            position: 'bottom-right',
          })
        )
        .catch((err) => console.log(err));

      if (this.trxType == 'cash') {
        await api
          .post(`api/bills/`, {
            cost: this.amount_credited,
            trx_no: this.trans_id,
            special_discount: this.amountDiscount,
            special_discount_reason: this.specialDiscountReason,
            card_validity: this.doe_card,
            trx_type: 'cash',
            //
            type_bill: 'room',
            is_paid: false,
            bill_item: `Room Clearance`,
            card_no: this.cardData.card_no,
            bill_category: 'credit',
          })
          .catch((err) => console.log(err));
      }
      if (this.trxType == 'card') {
        await api
          .post(`api/bills/`, {
            cost: this.amount_credited,
            trx_no: this.trans_id,
            special_discount: this.amountDiscount,
            special_discount_reason: this.specialDiscountReason,
            card_validity: this.doe_card,
            trx_type: 'card',
            //
            type_bill: 'room',
            is_paid: false,
            bill_item: `Room Clearance`,
            card_no: this.cardData.card_no,
            bill_category: 'credit',
          })
          .catch((err) => console.log(err));
      }
      if (this.trxType == 'bkash') {
        await api
          .post(`api/bills/`, {
            cost: this.amount_credited,
            trx_no: this.trans_id,
            special_discount: this.amountDiscount,
            special_discount_reason: this.specialDiscountReason,
            card_validity: this.doe_card,
            trx_type: 'bkash',
            //
            type_bill: 'room',
            is_paid: false,
            bill_item: `Room Clearance`,
            card_no: this.cardData.card_no,
            bill_category: 'credit',
          })
          .catch((err) => console.log(err));
      }
      this.jeAddForMoreCredit();

      this.getResInfo();
      this.showPayment = false;
    },
    async jeAddForMoreCredit() {
      let dataTT = [];
      let dataCAO = [];
      let user = [];
      console.log(user);
      // await axios
      //     .get(`auth/currentuser/`)
      //     .then(response => {
      //         user = response.data
      //     })
      //     .catch(error => {
      //             console.log(error)
      //         })
      await api
        .get('api/Transaction_table/')
        .then((response) => {
          dataTT = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      await api
        .get('api/coa/')
        .then((response) => {
          dataCAO = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      let c = 1;
      if (!dataTT.length == 0) {
        c = parseInt(dataTT[dataTT.length - 1].journal_id) + 1;
      }
      let q = 0;
      for (let i = 0; i < dataCAO.length; i++) {
        if (dataCAO[i].cost_center === 'room_clearance' && dataCAO[i].type === 'Credit') {
          console.log(dataCAO[i].id);
          q = dataCAO[i].id;
        }
      }
      const a = {
        description: 'Room Clearance', //change
        date: new Date().toISOString().substr(0, 10),
        account_id: q,
        credited_by: 'FrontDesk',
        reference: this.cardData.card_no, //change
        credit: this.amount_credited, //change
        debit: 0, //change
        memo: 'R' + this.resInfo.room, //change
        journal_id: c,
        auto_journal: true,
      };
      await api.post('api/Transaction_table/', a).catch((err) => {
        console.log(err);
      });
    },
    async genBills() {
      let tempSplit = [];
      this.resInfo.secondary_room;
      console.log(this.resInfo.secondary_room);
      tempSplit = this.resInfo.secondary_room.split(',');
      tempSplit.pop();
      this.roomsCollected.push(this.resInfo.room);

      this.roomsCollected.push(tempSplit);
      this.roomsCollected = this.roomsCollected.flat();
      console.log(this.roomsCollected);

      for (let i = 0; i < this.roomsCollected.length; i++) {
        console.log('number yi', this.roomsCollected[i]);
        await api
          .get(`api/rmrt/${this.roomsCollected[i]}/`)
          .then((res) => {
            this.roomInfo.push(res.data);
          })
          .catch((err) => console.log(err));
      }

      console.table(this.roomInfo);
      this.cardString = this.resInfo.room + this.resInfo.id.toString().padStart(5, '0');
      console.table(this.cardString);

      await api
        .post(`api/card/`, {
          card_no: this.cardString,
          room_no: this.resInfo.room,
          cust_id: this.resInfo.cust_id,
          rev_id: this.$route.params.id,
          secondary_room: this.resInfo.secondary_room,
        })
        .catch((err) => {
          console.log(err);
        });

      for (let i = 0; i < this.roomInfo.length; i++) {
        console.log('room count', this.roomInfo[i]);
        this.totalAmount += parseInt(this.roomInfo[i].cost) * parseInt(this.resInfo.No_days) + parseInt(this.roomInfo[i].cost) * parseInt(this.resInfo.No_days) * (this.companyInfo.tax / 100) + parseInt(this.roomInfo[i].cost) * parseInt(this.resInfo.No_days) * (this.companyInfo.service_charge / 100);

        await api
          .post(`api/bills/`, {
            type_bill: 'room_rent',
            cost: parseInt(this.roomInfo[i].cost) * parseInt(this.resInfo.No_days),
            vat_amount: parseInt(this.roomInfo[i].cost) * parseInt(this.resInfo.No_days) * (this.companyInfo.tax / 100),
            sc_amount: parseInt(this.roomInfo[i].cost) * parseInt(this.resInfo.No_days) * (this.companyInfo.service_charge / 100),
            discount: this.roomInfo[i].discount,
            is_paid: false,
            bill_item: `room ${this.roomInfo[i].room_no}`,
            card_no: this.cardString,
            item_quantity: this.resInfo.No_days,
            bill_category: 'debit',
            special_discount: 0,
          })
          .then(
            toast({
              message: 'Bills are generated',
              type: 'is-info',
              dismissible: true,
              pauseOnHover: true,
              duration: 2000,
              position: 'bottom-right',
            })
          )
          .catch((err) => console.log(err));
      }
      //paste here
      await this.jeAddForDebit();

      await api
        .put(`api/reservation/${this.$route.params.id}/`, {
          status: this.resInfo.status,
          cust_id: this.resInfo.cust_id,
          total_amount: this.totalAmount,
          total_due: this.totalAmount,
        })
        .catch((err) => {
          console.log(err);
        });

      //bill has been generated
      location.reload();
    },
    async jeAddForDebit() {
      let dataTT = [];
      let dataCAO = [];
      let user = [];
      console.log(user);
      // await axios
      //     .get(`auth/currentuser/`)
      //     .then(response => {
      //         user = response.data
      //     })
      //     .catch(error => {
      //             console.log(error)
      //         })
      await api
        .get('api/Transaction_table/')
        .then((response) => {
          dataTT = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      await api
        .get('api/coa/')
        .then((response) => {
          dataCAO = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      let c = 1;
      if (!dataTT.length == 0) {
        c = parseInt(dataTT[dataTT.length - 1].journal_id) + 1;
      }
      let q = 0;
      for (let i = 0; i < dataCAO.length; i++) {
        if (dataCAO[i].cost_center === 'room_rent' && dataCAO[i].type === 'Debit') {
          console.log(dataCAO[i].id);
          q = dataCAO[i].id;
        }
      }
      for (let i = 0; i < this.roomInfo.length; i++) {
        const a = {
          description: 'Room Rent', //change
          date: new Date().toISOString().substr(0, 10),
          account_id: q,
          credited_by: 'FrontDesk',
          reference: this.cardData.card, //change
          credit: 0, //change
          debit: parseInt(parseInt(this.roomInfo[i].cost) * parseInt(this.resInfo.No_days) + parseInt(this.roomInfo[i].cost) * parseInt(this.resInfo.No_days) * (this.companyInfo.tax / 100) + parseInt(this.roomInfo[i].cost) * parseInt(this.resInfo.No_days) * (this.companyInfo.service_charge / 100)), //change
          memo: 'R' + this.roomInfo[0].room_no, //change
          journal_id: c,
          auto_journal: true,
        };
        await api.post('api/Transaction_table/', a).catch((err) => {
          console.log(err);
        });
      }
    },
    async showBookedBill() {
      this.roomCost = 0;
      console.log(' res info', this.resInfo);
      this.resInfo.previous_discounts = 0;

      // this.resInfo.total_due = 0
      this.resInfo.total_credit = 0;

      // await axios.get('/auth/currentuser/'
      //     ).then(res =>{
      //         console.log(res.data.role)
      //         this.userInfo = res.data.company
      //     }).catch(err => console.log(err))
      console.log(this.userInfo);

      await axios
        .get(`/auth/company/1/`)
        .then((res) => {
          this.companyInfo = res.data;
        })
        .catch((err) => console.log(err));
      console.log(this.companyInfo);

      await api.get(`api/card/?rev_id=${this.$route.params.id}`).then((res) => {
        this.cardData = res.data[0];
        console.log(this.cardData);
      });
      console.log('cardData', this.cardData);

      await api.get(`api/bills/?card_no=${this.cardData.card_no}`).then((res) => {
        this.billData = res.data;
        console.log(this.billData);
      });
      this.billModal = true;
      console.table(this.billData);

      for (let i = 0; i < this.billData.length; i++) {
        console.log(this.billData[i]);
        this.roomCost += parseInt((this.billData[i].cost / this.billData[i].item_quantity) * this.billData[i].item_quantity);
      }
      console.log('lo', this.roomCost);
      this.generatedBill = true;
      this.billModal = true;
    },
    async bookedPayment() {
      console.log(this.resInfo.total_due);
      console.log(this.amount_credited);
      this.due_amount = parseInt(this.resInfo.total_amount) - (parseInt(this.amount_credited) + parseInt(this.amountDiscount));
      console.log(this.due_amount);
      this.creditCalculated = parseInt(this.amount_credited);
      console.log(this.creditCalculated);

      await api
        .put(`api/reservation/${this.resInfo.id}/`, {
          cust_id: this.resInfo.cust_id,
          status: 'reserved',
          total_due: this.due_amount,
          total_credit: this.creditCalculated,
          previous_discounts: parseInt(this.amountDiscount),
        })
        .then(
          toast({
            message: 'Rooms are reserved',
            type: 'is-info',
            dismissible: true,
            pauseOnHover: true,
            duration: 2000,
            position: 'bottom-right',
          })
        )
        .catch((err) => console.log(err));

      if (this.trxType == 'cash') {
        await api
          .post(`api/bills/`, {
            cost: this.amount_credited,
            trx_no: this.trans_id,
            special_discount: this.amountDiscount,
            special_discount_reason: this.specialDiscountReason,
            card_validity: this.doe_card,
            trx_type: 'cash',
            //
            type_bill: 'room',
            is_paid: false,
            bill_item: `Advance`,
            card_no: this.cardData.card_no,
            bill_category: 'credit',
          })
          .catch((err) => console.log(err));
      }
      if (this.trxType == 'card') {
        await api
          .post(`api/bills/`, {
            cost: this.amount_credited,
            trx_no: this.trans_id,
            special_discount: this.amountDiscount,
            special_discount_reason: this.specialDiscountReason,
            card_validity: this.doe_card,
            trx_type: 'card',
            //
            type_bill: 'room',
            is_paid: false,
            bill_item: `Advance`,
            card_no: this.cardData.card_no,
            bill_category: 'credit',
          })
          .catch((err) => console.log(err));
      }
      if (this.trxType == 'bkash') {
        await api
          .post(`api/bills/`, {
            cost: this.amount_credited,
            trx_no: this.trans_id,
            special_discount: this.amountDiscount,
            special_discount_reason: this.specialDiscountReason,
            card_validity: this.doe_card,
            trx_type: 'bkash',
            //
            type_bill: 'room',
            is_paid: false,
            bill_item: `Advance`,
            card_no: this.cardData.card_no,
            bill_category: 'credit',
          })
          .catch((err) => console.log(err));
      }
      await this.jeAddForCredit();

      location.reload();
    },
    async jeAddForCredit() {
      let dataTT = [];
      let dataCAO = [];
      let user = [];
      console.log(user);
      // await axios
      //     .get(`auth/currentuser/`)
      //     .then(response => {
      //         user = response.data
      //     })
      //     .catch(error => {
      //             console.log(error)
      //         })
      await api
        .get('api/Transaction_table/')
        .then((response) => {
          dataTT = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      await api
        .get('api/coa/')
        .then((response) => {
          dataCAO = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      let c = 1;
      if (!dataTT.length == 0) {
        c = parseInt(dataTT[dataTT.length - 1].journal_id) + 1;
      }
      let q = 0;
      for (let i = 0; i < dataCAO.length; i++) {
        if (dataCAO[i].cost_center === 'advance' && dataCAO[i].type === 'Credit') {
          console.log(dataCAO[i].id);
          q = dataCAO[i].id;
        }
      }
      const a = {
        description: 'Advance', //change
        date: new Date().toISOString().substr(0, 10),
        account_id: q,
        credited_by: 'Frontdesk',
        reference: this.cardData.card_no, //change
        credit: this.amount_credited, //change
        debit: 0, //change
        memo: 'R' + this.resInfo.room, //change
        journal_id: c,
        auto_journal: true,
      };
      await api.post('api/Transaction_table/', a).catch((err) => {
        console.log(err);
      });
    },
    async getModRoom() {
      this.secondaryRoomInfo.length = 0;
      await api
        .get(`api/rmrt/${this.resInfo.room}/`)
        .then((res) => {
          this.primaryRoomInfo = res.data;
        })
        .catch((err) => console.log(err));
      console.log('primary', this.primaryRoomInfo);
      console.log(this.roomNosFlat, 'room flat');

      for (let i = 1; i < this.roomNosFlat.length; i++) {
        await api
          .get(`api/rmrt/${this.roomNosFlat[i]}/`)
          .then((res) => {
            this.secondaryRoomInfo.push(res.data);
          })
          .catch((err) => console.log(err));
      }
      console.log('secondary', this.secondaryRoomInfo);
      console.log(this.resInfo);
      if (this.resInfo.secondary_room != null && this.resInfo.secondary_room != '') {
        this.tempSplit = this.resInfo.secondary_room.split(',');

        console.log('yo man', this.tempSplit);
      }
    },
    OnFileUpload(event) {
      this.pot = event.target.files[0];
      console.log(event);
    },

    async uploadImageHandler() {
      const fd = new FormData();

      //call cuspic here with cus id
      console.log(this.resInfo.cust_id);

      fd.append('pic', this.pot);
      fd.append('cust_id', this.custInfo.id);

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };

      await api
        .get(`api/cuspic/?cust_id=${this.custInfo.id}`)
        .then((res) => {
          this.cusPicData = res.data;
        })
        .catch((err) => console.log(err));
      console.log(this.cusPicData);

      if (this.cusPicData.length == 0) {
        console.log('hello');
        await api.post(`api/cuspic/`, fd, config).then((res) => {
          console.log('pic upload: ', res.data);
        });
      } else {
        await api.put(`api/cuspic/${this.cusPicData.cust_id}`, fd, config).then((res) => {
          console.log('pic upload: ', res.data);
        });
      }
      console.log(config);
    },
    async submitGuestAndResModInfo() {
      await api
        .put(`api/customer/${this.custInfo.id}/`, {
          first_name: this.firstName,
          last_name: this.lastName,
          email: this.email,
          address: this.address,
          phone: this.phone,
          gender: this.genderSelection,
          nid: this.nidDl,
          country: this.country,
          nationality: this.nationality,
          occupation: this.occupation,
          passport: this.passport,
          passport_exp: this.dof,
          visa_type: this.visa,
          visa_issue: this.vdoi,
          visa_expiry: this.vdof,
        })
        .then(
          toast({
            message: 'Info has been updated',
            type: 'is-info',
            dismissible: true,
            pauseOnHover: true,
            duration: 2000,
            position: 'bottom-right',
          })
        )
        .catch((err) => console.log(err));

      await api
        .put(`api/reservation/${this.resInfo.id}/`, {
          check_in_date: this.resInfo.checkInDate,
          check_in_time: this.checkInTime,
          cust_id: this.resInfo.cust_id,
          room: this.resInfo.room,
          source: 'FrontDesk',
          status: this.resInfo.status,
          check_out_date: this.resInfo.checkOutDate,
          check_out_time: this.checkOutTime,
          no_guest: this.guestNum,
          special_ins: this.specialIns,
          extra_breakfast: this.extraB,
          No_days: this.resInfo.No_days,
          adults: this.adultsNum,
          childs: this.childNum,
        })
        .then(
          toast({
            message: 'Reservation Details has been updated',
            type: 'is-info',
            dismissible: true,
            pauseOnHover: true,
            duration: 2000,
            position: 'bottom-right',
          })
        )
        .catch((err) => console.log(err));

      this.uploadImageHandler();
    },
  },
};
</script>

<style>
.tab-contents .content {
  display: none;
}
.tab-contents .content.is-active {
  display: block;
}

.button:hover {
  transform: scale(1.1);
}
.button {
  transition: all 0.2s ease-in-out;
}
.textHover:hover {
  text-decoration: underline;
  color: #0c79d9 !important;
}

.cancelIcon {
  color: #d3d3d3;
}

.cancelIcon:hover {
  color: #ff5349;
}
</style>